(function () {
  "use strict";

  $(function () {
    $("#js-hamburger").on("click", function () {
      const $isExpanded = $(this).attr("aria-expanded") === "true";
      const $isHidden = $("#js-hamburger-menu").attr("aria-hidden") === "true";
      $(this).attr("aria-expanded", !$isExpanded);
      $("#js-hamburger-menu").attr("aria-hidden", !$isHidden);
    });

    // ページ内のスムーススクロール

    $('a[href*="#"]').click(function (e) {
      let target = $(this.hash === "" ? "html" : this.hash);
      if (target.length) {
        e.preventDefault();
        let headerHeight;
        if (window.matchMedia("(min-width: 1024px)").matches) {
          headerHeight = 70;
        } else {
          headerHeight = 0;
        }
        let position = target.offset().top - headerHeight;

        $("body,html").animate({ scrollTop: position }, "swing");
      }
    });

    let urlHash = location.hash;
    if (urlHash) {
      let target = $(urlHash);
      if (urlHash.startsWith("#info-anchor")) {
        $("#info-anchor").addClass("-active").next(".js-accordion-content").slideDown(0);
        history.replaceState(null, "", window.location.pathname);
        $("html,body").stop().scrollTop(0);

        $(window).on("load", function () {
          let position = target.offset().top;
          $("html, body").animate({ scrollTop: position }, 500);
          history.replaceState(null, "", window.location.pathname + urlHash);
        });
      } else {
        if (target.length) {
          history.replaceState(null, "", window.location.pathname);
          $("html,body").stop().scrollTop(0);

          $(window).on("load", function () {
            let position = target.offset().top;
            $("html, body").animate({ scrollTop: position }, 500);
            history.replaceState(null, "", window.location.pathname + urlHash);
          });
        }
      }
    }

    // アコーディオン
    $(".js-accordion").click(function () {
      $(this).toggleClass("-active").next(".js-accordion-content").slideToggle();
    });

    // テキストエリア
    const textareaElems = document.querySelectorAll("[data-inquiry]");
    textareaElems.forEach((textareaElem) => {
      const index = textareaElem.getAttribute("data-inquiry");
      const currentLengthElem = document.querySelector(`[data-current-length="${index}"]`);

      textareaElem.addEventListener("input", (event) => {
        currentLengthElem.innerText = event.target.value.length;
      });
    });
  }); // END

  function checkVisibility() {
    $(".js-header-register").each(function () {
      const $this = $(this);
      const windowTop = $(window).scrollTop();
      const elementTop = 120;

      if (windowTop > elementTop || windowTop + $(window).height() < elementTop) {
        if (!$this.hasClass("-fixed")) {
          $(this).parent().addClass("-fixed");
        }
      } else {
        $(this).parent().removeClass("-fixed");
      }
    });
  }

  $(window).on("scroll resize", checkVisibility);
  checkVisibility();
})();
